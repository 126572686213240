import React from 'react';

import loadingImg from '../../public/images/loading_490x490.gif';

const PageLoading = () => (
	<main className="main text-center">
		<div className="container-fluid">
			<div className="page-hero">
				<img className="page-hero__img" src={loadingImg} alt="loading" />
			</div>
			<div className="row">
				<div className="col-12">
					<p>
						Uff!
						<br />
						Your calculation is giving us a hard time.
					</p>
				</div>
			</div>
		</div>
	</main>
);

export default PageLoading;
