import { connect } from 'react-redux';
import { applySpec } from 'ramda';

import PageFirst from '../components/PageFirst';
import { getAppState } from '../reducers';
import { setAppState, setApplicationId } from '../actions';

console.log({ setApplicationId });

export default connect(
	applySpec({
		appState: getAppState,
	}),
	{
		setAppState,
		setApplicationId,
	}
)(PageFirst);
