import PropTypes from 'prop-types';

// history shape
export const SHAPE_LOCATION = PropTypes.shape({
	hash: PropTypes.string,
	key: PropTypes.string,
	pathname: PropTypes.string,
	search: PropTypes.string,
});
export const SHAPE_HISTORY = PropTypes.shape({
	location: SHAPE_LOCATION,
	push: PropTypes.func,
	replace: PropTypes.func,
});

export const SHAPE_STRING_OR_NUMBER = PropTypes.oneOfType([
	PropTypes.string,
	PropTypes.number,
]);
export const SHAPE_JSX_ACCEPTABLE = PropTypes.oneOfType([
	PropTypes.string,
	PropTypes.number,
	PropTypes.node,
	PropTypes.bool,
]);
