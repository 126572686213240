import { RSAA, getJSON } from 'redux-api-middleware';

import {
	ADMIN_API_PATH_PREFIX,
	ADMIN_API_URL,
	APP_STATE,
	ActionTypes,
	FORM_ARE_YOU_DRUNK,
} from '../constants';

import { setAppState } from './ui';

export const requestApplicationRegister = (
	fields,
	applicationId,
	quote,
	quoteCode
) => dispatch => {
	dispatch({
		[RSAA]: {
			endpoint: `${ADMIN_API_URL}${ADMIN_API_PATH_PREFIX}/applications/${applicationId}`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json; charset=utf-8',
			},
			body: JSON.stringify({
				id: `${applicationId}`,
				quoteCode,
				pubId: null,
				quote,
				retype: fields[FORM_ARE_YOU_DRUNK.FIELDS.RETYPE],
			}),
			types: [
				ActionTypes.GET_APPLICATION_REGISTER_REQUEST,
				{
					type: ActionTypes.GET_APPLICATION_REGISTER_SUCCESS,
					payload: (action, state, res) => getJSON(res),
				},
				ActionTypes.GET_APPLICATION_REGISTER_FAILURE,
			],
		},
	}).then(({ error }) => {
		if (error) {
			dispatch(setAppState(APP_STATE.ERROR));
			return Promise.reject();
		}
		dispatch(setAppState(APP_STATE.THIRD));
		return Promise.resolve();
	});
};

export const requestLabelSave = (applicationId, label) => dispatch => {
	dispatch({
		[RSAA]: {
			endpoint: `${ADMIN_API_URL}${ADMIN_API_PATH_PREFIX}/applications/${applicationId}/label`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json; charset=utf-8',
			},
			body: JSON.stringify(label),
			types: [
				ActionTypes.SAVE_LABEL_REQUEST,
				ActionTypes.SAVE_LABEL_SUCCESS,
				ActionTypes.SAVE_LABEL_FAILURE,
			],
		},
	});
};
