import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';

import { mapPropertyX, mapPropertyY } from '../../utils';
import {
	CHARTS,
	CHART_BACKGROUND_COLOR,
	CHART_BORDER_COLOR,
} from '../../constants';

const BAR_CHART_OPTIONS = {
	responsive: true,
	legend: {
		display: false,
		position: 'right',
		labels: {
			fontColor: '#3a3e48',
			fontSize: 14,
		},
	},
	scales: {
		yAxes: [
			{
				ticks: {
					beginAtZero: true,
				},
			},
		],
	},
	plugins: {
		datalabels: {
			display: false,
			clamp: true,
			anchor: 'start',
			align: 'end',
			color: '#3a3e48',
			font: {
				size: 14,
			},
		},
	},
};

const BarChartRow = ({ chartData }) => (
	<div className="row">
		<div className="col-12">
			<Bar
				width={CHARTS.width}
				height={CHARTS.height}
				data={{
					datasets: [
						{
							data: mapPropertyY(chartData),
							backgroundColor: CHART_BACKGROUND_COLOR,
							borderColor: CHART_BORDER_COLOR,
							borderWidth: 2,
						},
					],
					labels: mapPropertyX(chartData),
				}}
				options={BAR_CHART_OPTIONS}
			/>
		</div>
	</div>
);

BarChartRow.propTypes = {
	chartData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default BarChartRow;
