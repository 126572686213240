import React from 'react';
import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';

import { APP_STATE } from '../constants';
import PageCheers from '../containers/PageCheers';
import PageError from '../containers/PageError';
import PageFirst from '../containers/PageFirst';
import PageSecond from '../containers/PageSecond';
import PageThird from '../containers/PageThird';
import ScrollToTop from '../containers/ScroolToTop';

import PageLoading from './PageLoading';
import StripesRow from './StripesRow';
import Header from './Header';
import Footer from './Footer';

const getAppStateComponent = (state) => {
	switch (state) {
		case APP_STATE.CHEERS:
			return <PageCheers />;
		case APP_STATE.ERROR:
			return <PageError />;
		case APP_STATE.FIRST:
			return <PageFirst />;
		case APP_STATE.LOADING:
			return <PageLoading />;
		case APP_STATE.SECOND:
			return <PageSecond />;
		case APP_STATE.THIRD:
			return <PageThird />;
		default:
			return null;
	}
};

const showFooter = (state) => {
	switch (state) {
		case APP_STATE.CHEERS:
		case APP_STATE.FIRST:
			return true;
		default:
			return false;
	}
};

const App = ({ appState }) => (
	<ScrollToTop>
		<div
			className={cx('main-col stripes stripes--white', {
				'main-col--footer': showFooter(appState),
			})}
		>
			<StripesRow />
			<Header />
			{getAppStateComponent(appState)}
			{showFooter(appState) ? <Footer /> : null}
		</div>
	</ScrollToTop>
);

App.propTypes = {
	appState: PropTypes.string.isRequired,
};

export default App;
