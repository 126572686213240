export const INIT_APPLICATION_DATA = {
	username: '',
};

export const INIT_QUOTES = {
	version: '1.1',
	quotes: [
		{
			quote:
				"Here's to alcoh0l: the cause of, and answer to, all of life's probl3ms.",
			author: 'Matt Groening',
			id: '1.0',
		},
		{
			quote:
				"H3re's to alcohol: the cause of, and answ3r to, all of life's problems.",
			author: 'Matt Groening',
			id: '1.1',
		},
		{
			quote:
				"Here's to alcohol: the cause of, and answer to, all of l1fe's problem5.",
			author: 'Matt Groening',
			id: '1.2',
		},
		{
			quote:
				"I'm very serious about no alcoh0l, no drugs. Life is too b3autiful.",
			author: 'Jim Carrey',
			id: '2.0',
		},
		{
			quote:
				"I'm very s3rious about n0 alcohol, no drugs. Life is too be4utiful.",
			author: 'Jim Carrey',
			id: '2.1',
		},
		{
			quote:
				"I'm very 5erious about no alc0hol, no drugs. L1fe is too beautiful.",
			author: 'Jim Carrey',
			id: '2.2',
		},
		{
			quote:
				'I have taken more out of alcoh0l than alcohol has tak3n out of me.',
			author: 'Winston Churchill',
			id: '3.0',
		},
		{
			quote:
				'I have taken more out of alc0hol than alcoh0l has taken out of me.',
			author: 'Winston Churchill',
			id: '3.1',
		},
		{
			quote:
				'I h4ve taken more out of alcohol than alc0hol has taken out of me.',
			author: 'Winston Churchill',
			id: '3.2',
		},
		{
			quote:
				'No matter how you handle alcoh0l at your wedding, you will most lik3ly be upsetting someone.',
			author: 'Emily V. Gordon',
			id: '4.0',
		},
		{
			quote:
				'No matter how you h4ndle alcohol at your w3dding, you will most likely be ups3tting someone.',
			author: 'Emily V. Gordon',
			id: '4.1',
		},
		{
			quote:
				'No matt3r how you handle 4lcohol at your wedding, you will most likely be upsetting s0meone.',
			author: 'Emily V. Gordon',
			id: '4.2',
		},
		{
			quote:
				"Alcoh0l may be man's worst enemy, but the bible says love your en3my.",
			author: 'Frank Sinatra',
			id: '5.0',
		},
		{
			quote:
				"4lcohol may be man's worst enemy, but the b1ble says love your enemy.",
			author: 'Frank Sinatra',
			id: '5.1',
		},
		{
			quote:
				"Alcohol may be m4n's worst en3my, but the bible says l0ve your enemy.",
			author: 'Frank Sinatra',
			id: '5.2',
		},
		{
			quote:
				'In wine th3re is wisdom, in beer there is Freedom, in water there is bacteria.',
			author: 'Benjamin Franklin',
			id: '6.0',
		},
		{
			quote:
				'In w1ne there is wisdom, in b3er there is Freedom, in water there is bacteria.',
			author: 'Benjamin Franklin',
			id: '6.1',
		},
		{
			quote:
				'In wine there is w1sdom, in beer there is Fre3dom, in water there is b4cteria.',
			author: 'Benjamin Franklin',
			id: '6.2',
		},
		{
			quote: 'Beer is pr0of that God loves us and w4nts us to be happy.',
			author: 'Benjamin Franklin',
			id: '7.0',
		},
		{
			quote: 'B3er is proof tha7 God loves us and want5 us to be happy.',
			author: 'Benjamin Franklin',
			id: '7.1',
		},
		{
			quote: 'Beer is proof that God loves us and wants us to be h4ppy.',
			author: 'Benjamin Franklin',
			id: '7.2',
		},
		{
			quote: 'I cook with w1ne, sometimes I even add it t0 the food.',
			author: 'W.C. Fields',
			id: '8.0',
		},
		{
			quote: 'I co0k with wine, sometimes I ev3n add it to the food.',
			author: 'W.C. Fields',
			id: '8.1',
		},
		{
			quote: 'I cook with wine, s0metimes I even add it to the f0od.',
			author: 'W.C. Fields',
			id: '8.2',
		},
		{
			quote: 'Drinking is fun! It m4kes me feel h0rrible and sexy!',
			author: 'Warren Ellis',
			id: '9.0',
		},
		{
			quote: 'Drinking is fun! It makes me fe3l horrible and sexy!',
			author: 'Warren Ellis',
			id: '9.1',
		},
		{
			quote: 'Dr1nking is fun! It makes me feel horrible and s3xy!',
			author: 'Warren Ellis',
			id: '9.2',
		},
		{
			quote:
				'Drink b3cause you are happy, but never becaus3 you are miserable.',
			author: 'G.K. Chesterton, Heretics',
			id: '10.0',
		},
		{
			quote:
				'Dr1nk because you are happy, but n3ver because you are mis3rable.',
			author: 'G.K. Chesterton, Heretics',
			id: '10.1',
		},
		{
			quote:
				'Drink because you are h4ppy, but never b3cause you are miserable.',
			author: 'G.K. Chesterton, Heretics',
			id: '10.2',
		},
		{
			quote:
				"Everybody's got to b3lieve in something. I beli3ve I'll have another beer.",
			author: 'W.C. Fields',
			id: '11.0',
		},
		{
			quote:
				"Everybody's got to believe in s0mething. I believe I'll have anoth3r beer.",
			author: 'W.C. Fields',
			id: '11.1',
		},
		{
			quote:
				"Everyb0dy's got to believe in something. I believe I'll have another b3er.",
			author: 'W.C. Fields',
			id: '11.2',
		},
		{
			quote: "I'd rather h4ve a bottle in front of m3 than a frontal lobotomy.",
			author: 'Dorothy Parker',
			id: '12.0',
		},
		{
			quote: "I'd rather hav3 a bottle in front of me than a front4l lobotomy.",
			author: 'Dorothy Parker',
			id: '12.1',
		},
		{
			quote: "I'd rather have a bottl3 in front of me than a frontal lob0tomy.",
			author: 'Dorothy Parker',
			id: '12.2',
		},
		{
			quote:
				"There is no bad wh1skey. There are only s0me whiskeys that aren't as good as others.",
			author: 'Raymond Chandler',
			id: '13.0',
		},
		{
			quote:
				"There is no b4d whiskey. There are only some whiskeys that aren't as g0od as others.",
			author: 'Raymond Chandler',
			id: '13.1',
		},
		{
			quote:
				"There is no bad whiskey. 7here are only some wh1skeys that aren't as good as others.",
			author: 'Raymond Chandler',
			id: '13.2',
		},
		{
			quote: 'I like to se3 the glass as half full, h0pefully of jack daniels.',
			author: 'Darynda Jones',
			id: '14.0',
		},
		{
			quote: 'I like to see the glas5 as half full, h0pefully of jack daniels.',
			author: 'Darynda Jones',
			id: '14.1',
		},
		{
			quote: 'I like to see the 6lass as half full, hopefully of jack d4niels.',
			author: 'Darynda Jones',
			id: '14.2',
		},
		{
			quote: 'W4r and drink are the two things man is n3ver too poor to buy.',
			author: 'William Faulkners',
			id: '15.0',
		},
		{
			quote: 'War and dr1nk are the two things man is never too p0or to buy.',
			author: 'William Faulkners',
			id: '15.1',
		},
		{
			quote: 'War and drink are th3 two things man is nev3r too poor to buy.',
			author: 'William Faulkners',
			id: '15.2',
		},
		{
			quote: 'I would not put a thi3f in my mouth to ste4l my brains.',
			author: 'William Shakespeare',
			id: '16.0',
		},
		{
			quote: 'I would not put a th1ef in my mouth to 5teal my brains.',
			author: 'William Shakespeare',
			id: '16.1',
		},
		{
			quote: 'I would not put a 7hief in my mouth to steal my br4ins.',
			author: 'William Shakespeare',
			id: '16.2',
		},
		{
			quote:
				"99% of all probl3ms can be solved by money - and for the other 1% there's alcohol.",
			author: 'Quentin R. Bufogle',
			id: '17.0',
		},
		{
			quote:
				"99% of all pr0blems can be solved by money - and for the other 1% there's 4lcohol.",
			author: 'Quentin R. Bufogle',
			id: '17.1',
		},
		{
			quote:
				"99% of all problems can be s0lved by money - and for the oth3r 1% there's alcohol.",
			author: 'Quentin R. Bufogle',
			id: '17.2',
		},
		{
			quote: "Beer's intellectual. Wh4t a shame so many idiots dr1nk it.",
			author: 'Ray Bradbury',
			id: '18.0',
		},
		{
			quote: "Beer's int3llectual. What a sh4me so many !diots drink it.",
			author: 'Ray Bradbury',
			id: '18.1',
		},
		{
			quote: "B3er's intellectual. What a shame so m4ny idiots drink it.",
			author: 'Ray Bradbury',
			id: '18.2',
		},
		{
			quote:
				"Not everyone who dr1nks is a poet. Some of us drink b3cause we're not poets.",
			author: 'Dudley Moore',
			id: '19.0',
		},
		{
			quote:
				"Not everyon3 who drinks is a poet. Some of us drink because we're not po3ts.",
			author: 'Dudley Moore',
			id: '19.1',
		},
		{
			quote:
				"Not everyone who drinks is a po3t. Some of us drink bec4use we're not poets.",
			author: 'Dudley Moore',
			id: '19.2',
		},
		{
			quote:
				"I will never, ever drink whisk3y again. From n0w on, it's strictly sherry.",
			author: 'Libba Bray',
			id: '20.0',
		},
		{
			quote:
				"I will never, ever dr1nk whiskey again. From now on, it's str!ctly sherry.",
			author: 'Libba Bray',
			id: '20.1',
		},
		{
			quote:
				"I will never, ever drink wh1skey again. From now on, it's strictly sh3rry.",
			author: 'Libba Bray',
			id: '20.2',
		},
		{
			quote:
				'Too much of anything is b4d, but too much Ch4mpagne is just right.',
			author: 'F. Scott Fitzgerald',
			id: '21.0',
		},
		{
			quote:
				'Too much of anyth!ng is bad, but too much Champagn3 is just r1ght.',
			author: 'F. Scott Fitzgerald',
			id: '21.1',
		},
		{
			quote:
				'T()o much of anything is bad, but too much Champagne is just right.',
			author: 'F. Scott Fitzgerald',
			id: '21.2',
		},
	],
};
