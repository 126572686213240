import { combineReducers } from 'redux';
import { path, prop } from 'ramda';

import {
	APP_STATE,
	ActionTypes,
	INIT_QUOTE,
	INIT_UI_LOCALE,
} from '../constants';

const version = (state = '', { type, payload }) =>
	type === ActionTypes.SET_VERSION ? payload : state;

const appState = (state = APP_STATE.FIRST, { type, payload }) =>
	type === ActionTypes.SET_APP_STATE ? payload : state;

const quote = (state = INIT_QUOTE, { type, payload }) =>
	type === ActionTypes.SET_QUOTE ? payload : state;

const hasFocus = (state = false, { type, payload }) =>
	type === ActionTypes.SET_FOCUS ? payload : state;

const keystrokesUnsupported = (state = false, { type }) =>
	type === ActionTypes.SET_KEYSTROKES_UNSUPPORTED ? true : state;

const locale = (state = INIT_UI_LOCALE) => state;

const applicationId = (state = null, action) => {
	if (action.type === ActionTypes.SET_APPLICATION_ID) {
		return action.payload;
	}
	return state;
};

const rootReducer = combineReducers({
	appState,
	applicationId,
	hasFocus,
	locale,
	quote,
	version,
	keystrokesUnsupported,
});

export default rootReducer;

export const getAppState = prop('appState');
export const getApplicationId = prop('applicationId');
export const getQuote = prop('quote');
export const isFocus = prop('hasFocus');
export const isKeystrokesUnsupported = prop('keystrokesUnsupported');

export const getVersion = prop('version');
export const getLocale = path(['locale', 'locale']);
