export const CHART_BACKGROUND_COLOR = [
	'rgba(255, 99, 132, 0.2)',
	'rgba(54, 162, 235, 0.2)',
	'rgba(255, 206, 86, 0.2)',
	'rgba(75, 192, 192, 0.2)',
	'rgba(153, 102, 255, 0.2)',
	'rgba(255, 159, 64, 0.2)',
];

export const CHART_BORDER_COLOR = [
	'rgba(255, 99, 132, 1)',
	'rgba(54, 162, 235, 1)',
	'rgba(255, 206, 86, 1)',
	'rgba(75, 192, 192, 1)',
	'rgba(153, 102, 255, 1)',
	'rgba(255, 159, 64, 1)',
];

export const CHARTS = {
	width: '80vh',
	height: '60vh',
};

export const CHART_TYPES = {
	PIE: 'PIE',
	BAR: 'BAR',
};
