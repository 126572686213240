import React from 'react';
import PropTypes from 'prop-types';
import { o, path, prop } from 'ramda';
import { defaultToEmptyArray } from 'ramda-extension';

// images
import stateSoberImg from '../../../public/images/state_sober.svg';
import stateBuzzedImg from '../../../public/images/state_buzzed.svg';
import stateDrunkImg from '../../../public/images/state_drunk.svg';
import stateSmashedImg from '../../../public/images/state_smashed.svg';
// import cheers3Img from '../../../public/images/cheers_3.svg';
import cheers3Img from '../../../public/images/cheers_3.png';
import { toFixed } from '../../utils';
import { APP_STATE, LABEL_OPTIONS, NUMBER_OF_INSIGHTS } from '../../constants';

import FeelingResponse from './FeelingResponse';
import StatisticsFact from './StatisticsFact';
import ChartRowWrapper from './ChartRowWrapper';

const getBehaviorCorrectingMistakes = e =>
	toFixed(prop('behaviorCorrectingMistakes', e), 0);
const getBehaviorTypingSpeed = e => toFixed(prop('behaviorTypingSpeed', e), 1);
const getLabel = prop('label');
const getMistakes = e => toFixed(prop('mistakes', e), 0);
const getInsights = o(defaultToEmptyArray, prop('insights'));

const getChart = path(['charts', '0']);

const getEvaluationComponent = (imgSrc, state, isPageHero = false) => (
	<div className="evaluation">
		{isPageHero ? (
			<div className="page-hero">
				<img className="page-hero__img" src={imgSrc} alt={state} />
			</div>
		) : (
			<img className="evaluation__img" src={imgSrc} alt={state} />
		)}
		<div className="evaluation__label">We think that you are</div>
		<span className="evaluation__state">{state}</span>
	</div>
);

const getEvaluation = label => {
	switch (label) {
		case LABEL_OPTIONS.SOBER:
			return getEvaluationComponent(stateSoberImg, 'Sober');
		case LABEL_OPTIONS.BUZZED:
			return getEvaluationComponent(stateBuzzedImg, 'Buzzed');
		case LABEL_OPTIONS.DRUNK:
			return getEvaluationComponent(stateDrunkImg, 'Drunk');
		case LABEL_OPTIONS.SMASHED:
			return getEvaluationComponent(stateSmashedImg, 'Smashed');
		case LABEL_OPTIONS.TROLL:
			return getEvaluationComponent(cheers3Img, 'Troll', true);
		default:
			return null;
	}
};

const PageThird = ({
	applicationId,
	applicationData,
	generateQuote,
	isKeystrokesUnsupported,
	quotes,
	repairsCount,
	requestLabelSave,
	setAppState,
}) => (
	<main className="main text-center">
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">{getEvaluation(getLabel(applicationData))}</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="statistics">
						<h3>How did you do?</h3>
						<div>
							{getInsights(applicationData)
								.filter((insight, index) => index < NUMBER_OF_INSIGHTS)
								.map(insight => (
									<StatisticsFact key={insight} text={insight} />
								))}
						</div>
						<div className="row gutters--small">
							<div className="col-4">
								<div className="statistics__value">
									{getBehaviorTypingSpeed(applicationData)}
								</div>
								<div className="statistics__label">Keystrokes per minute</div>
							</div>
							<div className="col-4">
								<div className="statistics__value">
									{getMistakes(applicationData)}
								</div>
								<div className="statistics__label">Number of errors</div>
							</div>
							<div className="col-4">
								<div className="statistics__value">
									{isKeystrokesUnsupported
										? repairsCount
										: getBehaviorCorrectingMistakes(applicationData)}
								</div>
								<div className="statistics__label">Repairs</div>
							</div>
						</div>
						<ChartRowWrapper chart={getChart(applicationData)} />
					</div>
				</div>
			</div>
			{getLabel(applicationData) === LABEL_OPTIONS.TROLL ? (
				<button
					type="submit"
					className="btn btn-primary"
					onClick={() => {
						setAppState(APP_STATE.FIRST);
						generateQuote(quotes);
					}}
				>
					Try Again!
				</button>
			) : (
				<div className="row">
					<div className="col-12">
						<h3>So how are you feeling?</h3>

						<FeelingResponse
							onClick={() => {
								setAppState(APP_STATE.CHEERS);
								requestLabelSave(applicationId, LABEL_OPTIONS.SOBER);
							}}
							imgSrc={stateSoberImg}
							color="green"
							buttonText="Sober"
							description="Awww, poor baby. Too afraid to have fun?"
						/>

						<FeelingResponse
							onClick={() => {
								setAppState(APP_STATE.CHEERS);
								requestLabelSave(applicationId, LABEL_OPTIONS.BUZZED);
							}}
							imgSrc={stateBuzzedImg}
							color="yellow"
							buttonText="Buzzed"
							description="Didn’t it hit you yet? Is your alcohol fake?"
						/>

						<FeelingResponse
							onClick={() => {
								setAppState(APP_STATE.CHEERS);
								requestLabelSave(applicationId, LABEL_OPTIONS.DRUNK);
							}}
							imgSrc={stateDrunkImg}
							color="orange"
							buttonText="Drunk!"
							description="Now we are talking. Hell yeah! You are drunk!"
						/>

						<FeelingResponse
							onClick={() => {
								setAppState(APP_STATE.CHEERS);
								requestLabelSave(applicationId, LABEL_OPTIONS.SMASHED);
							}}
							imgSrc={stateSmashedImg}
							color="red"
							buttonText="Smashed!"
							description="Sex with your ex."
						/>
					</div>
				</div>
			)}
		</div>
	</main>
);

PageThird.propTypes = {
	applicationData: PropTypes.shape({}).isRequired,
	applicationId: PropTypes.number.isRequired,
	generateQuote: PropTypes.func.isRequired,
	isKeystrokesUnsupported: PropTypes.bool.isRequired,
	quotes: PropTypes.arrayOf(PropTypes.object).isRequired,
	repairsCount: PropTypes.number.isRequired,
	requestLabelSave: PropTypes.func.isRequired,
	setAppState: PropTypes.func.isRequired,
};

export default PageThird;
