import { complement, ifElse } from 'ramda';
import { alwaysNull, isNilOrEmpty, isNumeric } from 'ramda-extension';

export const isNotNilOrEmpty = complement(isNilOrEmpty);

export const toNumber = ifElse(isNumeric, Number, alwaysNull);

export const toFixed = (value, digits = 0) => {
	const number = toNumber(value);
	if (number) {
		return +number.toFixed(digits);
	}
	return 0;
};

const buildtimeEnv = {
	SANALYTICS_SCRIPTS_URL: process.env.SANALYTICS_SCRIPTS_URL,
	SANALYTICS_TID: process.env.SANALYTICS_TID,
	ADMIN_API_URL: process.env.ADMIN_API_URL,
};

export const getEnv = (x) => typeof window.appConfig[x] === 'undefined'
		? buildtimeEnv[x]
		: window.appConfig[x];
