import React from 'react';
import PropTypes from 'prop-types';

const Button = ({
	bootstrapType,
	children,
	className,
	disabled,
	name,
	onClick,
	type,
}) => (
	<button
		className={`btn btn-${bootstrapType} ${className}`}
		disabled={disabled}
		name={name}
		onClick={onClick}
		type={type}
	>
		{children}
	</button>
);

Button.propTypes = {
	bootstrapType: PropTypes.oneOf([
		'primary',
		'secondary',
		'success',
		'danger',
		'warning',
		'info',
		'light',
		'dark',
		'link',
	]).isRequired,
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	name: PropTypes.string,
	onClick: PropTypes.func,
	type: PropTypes.string,
};

Button.defaultProps = {
	className: null,
	disabled: false,
	name: 'button',
	onClick: () => {},
	type: 'button',
};

export default Button;
