import { connect } from 'react-redux';
import { applySpec } from 'ramda';
import { formValueSelector } from 'redux-form';

import { FORM_ARE_YOU_DRUNK } from '../constants';
import PageSecond from '../components/PageSecond';
import {
	getAppState,
	getApplicationId,
	getQuote,
	isFocus,
	isKeystrokesUnsupported,
} from '../reducers';
import {
	addRepairsCount,
	requestApplicationRegister,
	setAppState,
	setFocus,
	setKeystrokesUnsupported,
} from '../actions';

const selector = formValueSelector(FORM_ARE_YOU_DRUNK.NAME);

export default connect(
	applySpec({
		applicationId: getApplicationId,
		appState: getAppState,
		isFocus,
		isKeystrokesUnsupported,
		quote: getQuote,
		retype: state => selector(state, FORM_ARE_YOU_DRUNK.FIELDS.RETYPE),
	}),
	{
		addRepairsCount,
		requestApplicationRegister,
		setAppState,
		setFocus,
		setKeystrokesUnsupported,
	}
)(PageSecond);
