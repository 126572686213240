import React from 'react';

import lundegaardImg from '../../public/images/lundegaard.svg';
import zoeaiImg from '../../public/images/zoeai.svg';
import { LUNDEGAARD_AI_URL } from '../constants';

const Footer = () => (
	<footer className="footer">
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 pb-1">
					&copy;&nbsp;lundegaard.ai&nbsp;{new Date().getFullYear()}. All rights
					reserved.
				</div>
				<div className="col-12">
					<a target="_blank" rel="noopener noreferrer" href={LUNDEGAARD_AI_URL}>
						<img
							className="px-1"
							src={zoeaiImg}
							alt="logo zoe.ai"
							title="zoe.ai"
						/>
					</a>
					<a target="_blank" rel="noopener noreferrer" href={LUNDEGAARD_AI_URL}>
						<img
							className="px-1"
							src={lundegaardImg}
							alt="logo Lundegaard a.s."
							title="Lundegaard a.s."
						/>
					</a>
				</div>
			</div>
		</div>
	</footer>
);

export default Footer;
