import React from 'react';

import headerLogoImg from '../../public/images/logo.svg';

import Grid from './Grid';

const Header = () => (
	<header className="header">
		<Grid container fluid>
			<Grid row>
				<Grid col={[12]} className="text-center">
					<img
						className="header__logo"
						src={headerLogoImg}
						alt="logo Drunky"
						title="logo Drunky"
					/>
				</Grid>
			</Grid>
		</Grid>
	</header>
);

export default Header;
