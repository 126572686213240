import { combineReducers } from 'redux';
import { o, path } from 'ramda';
import { reducer as formReducer } from 'redux-form';

import ui, * as fromUi from './ui';
import entities, * as fromEntities from './entities';

const rootReducer = combineReducers({
	ui,
	entities,
	form: formReducer,
});

export default rootReducer;

const getUi = path(['ui']);
const getEntities = path(['entities']);

export const getApplicationData = o(
	fromEntities.getApplicationData,
	getEntities
);
export const getQuotes = o(fromEntities.getQuotes, getEntities);
export const getRepairsCount = o(fromEntities.getRepairsCount, getEntities);

export const getAppState = o(fromUi.getAppState, getUi);
export const getApplicationId = o(fromUi.getApplicationId, getUi);
export const getVersion = o(fromUi.getVersion, getUi);
export const getLocale = o(fromUi.getLocale, getUi);
export const getQuote = o(fromUi.getQuote, getUi);
export const isFocus = o(fromUi.isFocus, getUi);
export const isKeystrokesUnsupported = o(fromUi.isKeystrokesUnsupported, getUi);
