import { keyMirror } from 'ramda-extension';

export default keyMirror({
	SET_VERSION: null,

	SET_APP_STATE: null,

	SET_KEYSTROKES_UNSUPPORTED: null,
	ADD_REPAIRS_COUNT: null,
	RESET_REPAIRS_COUNT: null,

	SET_APPLICATION_ID: null,
	SET_QUOTE: null,
	SET_FOCUS: null,

	GET_APPLICATION_REGISTER_REQUEST: null,
	GET_APPLICATION_REGISTER_SUCCESS: null,
	GET_APPLICATION_REGISTER_FAILURE: null,

	SAVE_LABEL_REQUEST: null,
	SAVE_LABEL_SUCCESS: null,
	SAVE_LABEL_FAILURE: null,
});
