import { connect } from 'react-redux';
import { applySpec } from 'ramda';

import PageCheers from '../components/PageCheers';
import { generateQuote, resetRepairsCount, setAppState } from '../actions';
import { getApplicationData, getQuotes } from '../reducers';

export default connect(
	applySpec({
		quotes: getQuotes,
		applicationData: getApplicationData,
	}),
	{
		generateQuote,
		resetRepairsCount,
		setAppState,
	}
)(PageCheers);
