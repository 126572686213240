import React from 'react';
import PropTypes from 'prop-types';

import errorImg from '../../public/images/error.svg';
import { APP_STATE } from '../constants';

const PageError = ({ generateQuote, quotes, setAppState }) => (
	<main className="main text-center pt-4">
		<div className="container-fluid">
			<div className="page-hero">
				<img className="page-hero__img" src={errorImg} alt="error" />
			</div>
			<div className="row">
				<div className="col-12">
					<p>
						Oooops!
						<br />
						Something went wrong…
					</p>
					<button
						type="submit"
						className="btn btn-primary"
						onClick={() => {
							setAppState(APP_STATE.FIRST);
							generateQuote(quotes);
						}}
					>
						Try Again!
					</button>
				</div>
			</div>
		</div>
	</main>
);

PageError.propTypes = {
	generateQuote: PropTypes.func.isRequired,
	quotes: PropTypes.arrayOf(PropTypes.object).isRequired,
	setAppState: PropTypes.func.isRequired,
};

export default PageError;
