import React from 'react';
import PropTypes from 'prop-types';

const Input = ({ id, input, name, placeholder, required, type, ...rest }) => (
	<input
		id={id}
		name={name}
		placeholder={placeholder}
		required={required}
		type={type}
		{...input}
		{...rest}
	/>
);

Input.propTypes = {
	id: PropTypes.string,
	input: PropTypes.object,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	required: PropTypes.string,
	type: PropTypes.string.isRequired,
};

Input.defaultProps = {
	id: null,
	name: null,
	placeholder: null,
	required: null,
};

export default Input;
