import React from 'react';
import PropTypes from 'prop-types';

const Input = ({
	id,
	input,
	name,
	placeholder,
	required,
	type,
	meta: { touched, error },
	...rest
}) => (
	<div>
		<input
			id={id}
			name={name}
			placeholder={placeholder}
			required={required}
			type={type}
			{...input}
			{...rest}
		/>
		{touched && error && <span className="field-error">{error}</span>}
	</div>
);

Input.propTypes = {
	id: PropTypes.string,
	input: PropTypes.object,
	meta: PropTypes.object,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	required: PropTypes.string,
	type: PropTypes.string.isRequired,
};

Input.defaultProps = {
	id: null,
	name: null,
	placeholder: null,
	required: null,
};

export default Input;
