import React from 'react';
import PropTypes from 'prop-types';

const FeelingResponse = ({
	onClick,
	imgSrc,
	buttonText,
	description,
	color,
}) => (
	<a className="state" href="#" onClick={onClick}>
		<img className="state__img" src={imgSrc} alt={buttonText} />
		<div className={`btn state__title state__title--${color}`}>
			{buttonText}
		</div>
		<span className="state__description">{description}</span>
	</a>
);

FeelingResponse.propTypes = {
	buttonText: PropTypes.string.isRequired,
	color: PropTypes.string,
	description: PropTypes.string.isRequired,
	imgSrc: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
};

FeelingResponse.defaultProps = {
	color: 'green',
};

export default FeelingResponse;
