import { connect } from 'react-redux';
import { applySpec } from 'ramda';

import PageThird from '../components/PageThird';
import {
	getApplicationData,
	getApplicationId,
	getQuotes,
	getRepairsCount,
	isKeystrokesUnsupported,
} from '../reducers';
import { generateQuote, requestLabelSave, setAppState } from '../actions';

export default connect(
	applySpec({
		applicationId: getApplicationId,
		applicationData: getApplicationData,
		isKeystrokesUnsupported,
		quotes: getQuotes,
		repairsCount: getRepairsCount,
	}),
	{
		generateQuote,
		requestLabelSave,
		setAppState,
	}
)(PageThird);
