const hasBug = () => {
	// inner should fill outer
	// if inner's height is 0, the browser has the bug

	// set up
	const outer = document.createElement('div');
	const inner = document.createElement('div');
	outer.setAttribute(
		'style',
		'display:-ms-flexbox; display:flex; min-height:100vh;'
	);
	outer.appendChild(inner);
	(document.body || document.documentElement).appendChild(outer);

	// test
	const bug = !inner.offsetHeight;

	// remove setup
	outer.parentNode.removeChild(outer);

	return bug;
};

function fixElementHeight($el) {
	// reset any previously set height
	$el.style.height = 'auto';

	// get el height (the one set via min-height in vh)
	const height = $el.offsetHeight;

	// manually set it in pixels
	$el.style.height = `${height }px`;
	// $el.style.height = 200 + 'px';
}

if (hasBug()) {
	// root element with min-height 100vh
	const $root = document.getElementById('root');

	fixElementHeight($root);

	// update the height on resize
	window.addEventListener('resize', function() {
		fixElementHeight($root);
	});
}
