import React from 'react';
import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';

const Spinner = ({ className, fixed }) => (
	<div
		className={cx(
			{
				'absolute--center-fixed': fixed,
				'absolute--center': !fixed,
			},
			className
		)}
	>
		<div className="spinner-border" role="status" />
	</div>
);

Spinner.propTypes = {
	className: PropTypes.string,
	fixed: PropTypes.bool,
};

Spinner.defaultProps = {
	className: null,
	fixed: false,
};

export default Spinner;
