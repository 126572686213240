import React from 'react';

const StripesRow = () => (
	<div className="row stripes__row no-gutters">
		<div className="col-4">
			<div className="stripes__item" />
		</div>
		<div className="col-4 offset-4">
			<div className="stripes__item" />
		</div>
	</div>
);

export default StripesRow;
