import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { o, prop } from 'ramda';
import { defaultToZero } from 'ramda-extension';

// import cheers2Img from '../../../public/images/cheers_2.svg';
import cheers2Img from '../../../public/images/cheers_2.png';
import handLeftImg from '../../../public/images/hand-left.svg';
import handRightImg from '../../../public/images/hand-right.svg';
import { APP_STATE, FORM_ARE_YOU_DRUNK } from '../../constants';
import { Button, ReduxFormInput } from '../CustomUIComponents';

const getQuote = prop('quote');
const getQuoteId = prop('id');

const getLength = prop('length');
const getLengthDefaultZero = o(defaultToZero, getLength);

const PageSecond = ({
	addRepairsCount,
	applicationId,
	handleSubmit,
	// isFocus,
	isKeystrokesUnsupported,
	quote,
	requestApplicationRegister,
	retype,
	setAppState,
	setFocus,
	setKeystrokesUnsupported,
}) => {
	useEffect(() => {
		sa('send', 'register', applicationId);
		sa('s-form:set', { formName: 'lover' });
		sa('s-form:track');
		sa('s-form:start');
		sa('s-biometrics:track');
	}, []);

	return (
		<main className="main text-center">
			<div className="container-fluid">
				<div className="page-hero">
					<img className="page-hero__img" src={cheers2Img} alt="cheers 2" />
				</div>
				<div className="row text--noselect">
					<div className="col-12">
						<div className="control">
							<div className="control__hand control__hand--left">
								<img src={handLeftImg} alt="hand left" />
							</div>
							<span className="control__text">{getQuote(quote)}</span>
							<div className="control__hand control__hand--right">
								<img src={handRightImg} alt="hand right" />
							</div>
						</div>
					</div>
					<div className="col-12">
						<form
							name="lover"
							onSubmit={handleSubmit(fields => {
								setAppState(APP_STATE.LOADING);
								requestApplicationRegister(
									fields,
									applicationId,
									getQuote(quote),
									getQuoteId(quote)
								);
								setFocus(false);

								sa('s-form:submit');
								sa('s-form:end');
							})}
						>
							<div className="form-group">
								<Field
									className="form-control"
									component={ReduxFormInput}
									name={FORM_ARE_YOU_DRUNK.FIELDS.RETYPE}
									placeholder="Retype the text above here..."
									type="text"
									id="control-text"
									autoCapitalize="off"
									autoComplete="off"
									spellCheck="false"
									autoCorrect="off"
									onKeyDown={e => {
										if (
											!isKeystrokesUnsupported &&
											e &&
											e.key === 'Unidentified'
										) {
											setKeystrokesUnsupported();
										}
									}}
									onFocus={() => {
										setFocus(true);
									}}
									onChange={e => {
										if (isKeystrokesUnsupported) {
											const oldLength = getLengthDefaultZero(retype);
											const newLength = getLengthDefaultZero(e.target.value);
											const difference = oldLength - newLength;
											if (difference > 0) {
												addRepairsCount(difference);
											}
										}
									}}
								/>
							</div>
							{retype ? (
								<Button type="submit" bootstrapType="primary">
									Send
								</Button>
							) : null}
						</form>
					</div>
				</div>
			</div>
		</main>
	);
};

PageSecond.propTypes = {
	addRepairsCount: PropTypes.func.isRequired,
	applicationId: PropTypes.number.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	// isFocus: PropTypes.bool.isRequired,
	isKeystrokesUnsupported: PropTypes.bool.isRequired,
	quote: PropTypes.shape().isRequired,
	requestApplicationRegister: PropTypes.func.isRequired,
	retype: PropTypes.string,
	setAppState: PropTypes.func.isRequired,
	setFocus: PropTypes.func.isRequired,
	setKeystrokesUnsupported: PropTypes.func.isRequired,
};

export default reduxForm({
	form: FORM_ARE_YOU_DRUNK.NAME,
})(PageSecond);
