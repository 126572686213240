import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { apiMiddleware } from 'redux-api-middleware';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import rootReducer from '../reducers';

const createStoreWithMiddleware = () => {
	const middlewares = [apiMiddleware, thunk];
	if (process.env.NODE_ENV === 'production') {
		return applyMiddleware(...middlewares)(createStore);
	}
	middlewares.push(logger);
	return composeWithDevTools(applyMiddleware(...middlewares))(createStore);
};

const configureStore = (initialState) =>
	createStoreWithMiddleware()(rootReducer, initialState);

export default configureStore;
