import React from 'react';
import PropTypes from 'prop-types';

import beerImg from '../../../public/images/beer.svg';

const StatisticsFact = ({ text }) => (
	<div className="statistics__fact">
		<img className="statistics__fact-img" src={beerImg} alt="beer" />
		<span className="statistics__fact-text">{text}</span>
	</div>
);

StatisticsFact.propTypes = {
	text: PropTypes.string.isRequired,
};

export default StatisticsFact;
