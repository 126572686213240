import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import ready from 'document-ready';

// chart.js labels
import 'chartjs-plugin-datalabels';

// IE 11 fixes
import 'isomorphic-fetch';
import './utils/ie11HeighFix';

// main scc + libs custom.css
import '../public/css/front.min.css';

import pck from '../package.json';

import Root from './containers/Root';
import configureStore from './store/configureStore';
import { init } from './actions';

const { version } = pck;

if (!Intl.PluralRules) {
	require('@formatjs/intl-pluralrules/polyfill');
	require('@formatjs/intl-pluralrules/dist/locale-data/cs');
}

if (!Intl.RelativeTimeFormat) {
	require('@formatjs/intl-relativetimeformat/polyfill');
	require('@formatjs/intl-relativetimeformat/dist/locale-data/cs');
}

const store = configureStore();
const history = createBrowserHistory();
store.dispatch(init(version, history));

ready(
	() =>
		void ReactDOM.render(
			<Root store={store} history={history} />,
			document.getElementById('root')
		)
);
