import { connect } from 'react-redux';
import { applySpec } from 'ramda';

import PageError from '../components/PageError';
import { generateQuote, setAppState } from '../actions';
import { getQuotes } from '../reducers';

export default connect(
	applySpec({
		quotes: getQuotes,
	}),
	{
		generateQuote,
		setAppState,
	}
)(PageError);
