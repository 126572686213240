import { combineReducers } from 'redux';
import { path, prop } from 'ramda';

import { ActionTypes, INIT_APPLICATION_DATA, INIT_QUOTES } from '../constants';

const applicationData = (state = INIT_APPLICATION_DATA, { type, payload }) => {
	switch (type) {
		case ActionTypes.GET_APPLICATION_REGISTER_SUCCESS:
			return payload;
		default:
			return state;
	}
};

const quotes = (state = INIT_QUOTES) => state;

const repairsCount = (state = 0, { type, payload }) => {
	switch (type) {
		case ActionTypes.ADD_REPAIRS_COUNT:
			return state + payload;
		case ActionTypes.RESET_REPAIRS_COUNT:
			return 0;
		default:
			return state;
	}
};

const rootReducer = combineReducers({
	applicationData,
	quotes,
	repairsCount,
});

export default rootReducer;

export const getApplicationData = prop('applicationData');
export const getQuotes = path(['quotes', 'quotes']);
export const getRepairsCount = prop('repairsCount');
