import {
	allPass,
	anyPass,
	equals,
	filter,
	map,
	o,
	pipe,
	prop,
	propSatisfies,
} from 'ramda';
import {
	defaultToEmptyArray,
	defaultToEmptyObject,
	isNotNil,
	isPositive,
} from 'ramda-extension';

import { CHART_TYPES } from '../constants';

import { isNotNilOrEmpty } from './utils';

const getX = prop('x');
const getY = prop('y');

export const mapPropertyX = pipe(
	defaultToEmptyArray,
	map(getX),
	filter(isNotNil)
);

export const mapPropertyY = pipe(
	defaultToEmptyArray,
	map(getY),
	filter(isNotNil)
);

const isPositiveY = propSatisfies(isPositive, 'y');

const getChartData = prop('data');
export const getChartDataWithPositiveY = pipe(
	getChartData,
	defaultToEmptyArray,
	filter(isPositiveY)
);

export const isValidChart = o(
	allPass([
		propSatisfies(isNotNilOrEmpty, 'text'),
		propSatisfies(
			anyPass([equals(CHART_TYPES.PIE), equals(CHART_TYPES.BAR)]),
			'type'
		),
		o(isNotNilOrEmpty, getChartDataWithPositiveY),
	]),
	defaultToEmptyObject
);
