import { getEnv } from '../utils';

export const URL = {
	ANY: '*',
	ROOT: '/',
};

export const ADMIN_API_URL = getEnv('ADMIN_API_URL');

export const ADMIN_API_PATH_PREFIX = '/drunky/api';

export const LUNDEGAARD_AI_URL = 'https://lundegaard.ai';
