import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'ramda';

import { getChartDataWithPositiveY, isValidChart } from '../../utils';
import { CHART_TYPES } from '../../constants';

import PieChartRow from './PieChartRow';
import BarChartRow from './BarChartRow';

const getChartText = prop('text');
const getChartType = prop('type');

const getChartByType = (chart) => {
	const filteredData = getChartDataWithPositiveY(chart);
	switch (getChartType(chart)) {
		case CHART_TYPES.PIE:
			return <PieChartRow chartData={filteredData} />;
		case CHART_TYPES.BAR:
			return <BarChartRow chartData={filteredData} />;
		default:
			return null;
	}
};

const ChartRowWrapper = ({ chart }) =>
	isValidChart(chart) ? (
		<div>
			<h3 className="mt-3 mb-1">{getChartText(chart)}</h3>
			{getChartByType(chart)}
		</div>
	) : null;

ChartRowWrapper.propTypes = {
	chart: PropTypes.shape({
		data: PropTypes.array,
		text: PropTypes.string,
		type: PropTypes.oneOf([CHART_TYPES.PIE, CHART_TYPES.BAR]),
	}).isRequired,
};

export default ChartRowWrapper;
