import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';

import { mapPropertyX, mapPropertyY } from '../../utils';
import {
	CHARTS,
	CHART_BACKGROUND_COLOR,
	CHART_BORDER_COLOR,
} from '../../constants';

const PIE_CHART_OPTIONS = {
	responsive: true,
	legend: {
		position: 'top',
		labels: {
			fontColor: '#3a3e48',
			fontSize: 14,
		},
	},
	plugins: {
		datalabels: {
			color: '#3a3e48',
			font: {
				size: 14,
			},
		},
	},
};

const PieChartRow = ({ chartData }) => (
	<div className="row">
		<div className="col-12">
			<Doughnut
				width={CHARTS.width}
				height={CHARTS.height}
				data={{
					datasets: [
						{
							data: mapPropertyY(chartData),
							backgroundColor: CHART_BACKGROUND_COLOR,
							borderColor: CHART_BORDER_COLOR,
						},
					],
					labels: mapPropertyX(chartData),
				}}
				options={PIE_CHART_OPTIONS}
			/>
		</div>
	</div>
);

PieChartRow.propTypes = {
	chartData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PieChartRow;
