import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'ramda';
import { isNilOrEmpty } from 'ramda-extension';

// import cheersImg from '../../../public/images/cheers_4.svg';
import beerImg from '../../../public/images/beer.svg';
import cheersImg from '../../../public/images/cheers_4.png';
import facebookImg from '../../../public/images/facebook.svg';
import qrImg from '../../../public/images/qr_84.svg';
import twitterImg from '../../../public/images/twitter.svg';
import whatsupImg from '../../../public/images/whatsup.svg';
import { APP_STATE } from '../../constants';
import { isNotNilOrEmpty, toFixed } from '../../utils';

const URL = 'https://drunky.ai';
const ENC_URL = encodeURIComponent(URL);
const TWITTER_TEXT = 'More about your drunk level';
const TWITTER_HASHTAGS = 'drunklevel,feeldrunk,soberornot,drunkyai';
const WHATS_UP_TEXT = `More about your #drunklevel #feeldrunk #soberornot #drunkyai ${URL}`;

const getBehaviorTypingSpeed = (e) =>
	toFixed(prop('behaviorTypingSpeed', e), 1);
const getLabel = prop('label');
const getMistakes = (e) => toFixed(prop('mistakes', e), 0);

const getShareMessage = (applicationData) => {
	const label = getLabel(applicationData);
	const typingSpeed = getBehaviorTypingSpeed(applicationData);
	const mistakes = getMistakes(applicationData);

	if (isNilOrEmpty(label)) {
		return '';
	}

	if (isNotNilOrEmpty(typingSpeed) && typingSpeed > 0) {
		return `I'm ${label} with ${typingSpeed} keystrokes per minute and ${mistakes} mistakes. `;
	} else {
		return `I'm ${label} with ${mistakes} mistakes. `;
	}
};

const PageCheers = ({
	applicationData,
	generateQuote,
	quotes,
	resetRepairsCount,
	setAppState,
}) => (
	<main className="main text-center">
		<div className="container-fluid">
			<div className="page-hero">
				<img className="page-hero__img" src={cheersImg} alt="cheers 4" />
			</div>
			<div className="row mb-2">
				<div className="col-12">
					<h2>Cheers mate!</h2>
					<p>
						Are you hanging out with a bunch of mates and wanna compare how
						drunk you are?
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<ul className="social">
						<li className="social__item">
							<a
								className="social__link"
								href={
									'https://wa.me/?' +
									`text=${encodeURIComponent(
										getShareMessage(applicationData) + WHATS_UP_TEXT
									)}`
								}
								rel="noopener noreferrer"
								target="_blank"
							>
								<img className="social__img" src={whatsupImg} alt="whatsup" />
							</a>
						</li>
						<li className="social__item">
							<a
								className="social__link"
								href={
									'https://www.facebook.com/sharer/sharer.php?' +
									`u=${ENC_URL}` +
									`&quote=${encodeURIComponent(
										getShareMessage(applicationData) + WHATS_UP_TEXT
									)}`
								}
								rel="noopener noreferrer"
								target="_blank"
							>
								<img className="social__img" src={facebookImg} alt="facebook" />
							</a>
						</li>
						<li className="social__item">
							<a
								className="social__link"
								href={
									'https://twitter.com/intent/tweet?' +
									`url=${ENC_URL}` +
									`&text=${encodeURIComponent(
										getShareMessage(applicationData) + TWITTER_TEXT
									)}` +
									`&hashtags=${encodeURIComponent(TWITTER_HASHTAGS)}`
								}
								rel="noopener noreferrer"
								target="_blank"
							>
								<img className="social__img" src={twitterImg} alt="twitter" />
							</a>
						</li>
					</ul>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="qr">
						<img className="qr__img" src={qrImg} alt="qr code" />
						<a className="qr__link" href="https://drunky.ai">
							drunky.ai
						</a>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<button
						type="submit"
						className="btn try-again"
						onClick={() => {
							setAppState(APP_STATE.FIRST);
							generateQuote(quotes);
							resetRepairsCount();
						}}
					>
						<img className="pr-1" src={beerImg} alt="beer" />
						<span>I wanna try again!</span>
					</button>
				</div>
			</div>
		</div>
	</main>
);

PageCheers.propTypes = {
	applicationData: PropTypes.shape().isRequired,
	generateQuote: PropTypes.func.isRequired,
	quotes: PropTypes.arrayOf(PropTypes.object).isRequired,
	resetRepairsCount: PropTypes.func.isRequired,
	setAppState: PropTypes.func.isRequired,
};

export default PageCheers;
