import { connect } from 'react-redux';
import { applySpec } from 'ramda';

import App from '../components/App';
import { getAppState } from '../reducers';

export default connect(
	applySpec({
		appState: getAppState,
	})
)(App);
