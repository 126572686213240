import { ActionTypes } from '../constants';

export const setVersion = version => ({
	type: ActionTypes.SET_VERSION,
	payload: version,
});

export const setAppState = state => ({
	type: ActionTypes.SET_APP_STATE,
	payload: state,
});

export const setQuote = quote => ({
	type: ActionTypes.SET_QUOTE,
	payload: quote,
});

export const setFocus = bool => ({
	type: ActionTypes.SET_FOCUS,
	payload: bool,
});

export const generateQuote = quotes => dispatch => {
	const quote = quotes[Math.floor(Math.random() * quotes.length)];
	dispatch(setQuote(quote));
};

export const setKeystrokesUnsupported = () => ({
	type: ActionTypes.SET_KEYSTROKES_UNSUPPORTED,
});

export const addRepairsCount = number => ({
	type: ActionTypes.ADD_REPAIRS_COUNT,
	payload: number,
});

export const resetRepairsCount = () => ({
	type: ActionTypes.RESET_REPAIRS_COUNT,
});

export const setApplicationId = id => ({
	type: ActionTypes.SET_APPLICATION_ID,
	payload: id,
});
