export const APP_STATE = {
	CHEERS: 'CHEERS',
	ERROR: 'ERROR',
	FIRST: 'FIRST',
	LOADING: 'LOADING',
	SECOND: 'SECOND',
	THIRD: 'THIRD',
};

export const LABEL_OPTIONS = {
	SOBER: 'SOBER',
	BUZZED: 'BUZZED',
	DRUNK: 'DRUNK',
	SMASHED: 'SMASHED',
	TROLL: 'TROLL',
};

export const APPLICATIONS_FILTER = {
	NONE: 'NONE',
	ALERT: 'ALERT',
	OPPORTUNITIES: 'OPPORTUNITIES',
};

export const NUMBER_OF_INSIGHTS = 1;
