/* eslint-disable react/require-default-props, no-tabs */
import React from 'react';
import PropTypes from 'prop-types';
import {
	__,
	append,
	converge,
	identity,
	ifElse,
	last,
	nth,
	useWith as ramdaUseWith,
	until,
} from 'ramda';
import {
	alwaysEmptyArray,
	cx,
	equalsLength,
	isNilOrEmpty,
	mapIndexed,
} from 'ramda-extension';

const duplicateLast = converge(append, [last, identity]);
const getBreakpointByIndex = nth(__, ['xs', 'sm', 'md', 'lg', 'xl']);
const fillBreakpoints = ifElse(
	isNilOrEmpty,
	alwaysEmptyArray,
	until(equalsLength(5), duplicateLast)
);

/**
 * Creates array of classNames for grid framework based on "xs, sm, md, lg"
 * breakpoints.
 *
 * @sig (String -> String -> String) -> [String] -> [String]
 *
 * @example
 *
 * 		classNamesByBreakpoint(
 * 			(col, breakpoint) => `col-${breakpoint}-${col}`,
 * 			[1, 2]
 * 		)
 * 		// ["col-xs-1", "col-sm-2", "col-md-2", "col-lg-2"]
 *
 */
const prepareClassNameFactory = ramdaUseWith(__, [
	identity,
	getBreakpointByIndex,
]);
const classNamesByBreakpoint = ramdaUseWith(mapIndexed, [
	prepareClassNameFactory,
	fillBreakpoints,
]);

const colToClassName = classNamesByBreakpoint(
	(cols, breakpoint) => `col-${breakpoint}-${cols}`
);
const offsetToClassName = classNamesByBreakpoint(
	(cols, breakpoint) => `offset-${breakpoint}-${cols}`
);

/**
 * Grid component is based on Twitter bootstraps naming for class names.
 * Can act as a container, row or column.
 *
 * If Grid is a container you can determine if it is fluid or not by `fluid`
 * property.
 * If Grid is a column you can add offset with `offset` property.
 *
 * Way you specify responsive behaviour is based on
 * https://github.com/jxnblk/styled-system#responsive-style-props.
 * One difference is that you must always pass an array.
 */
const Grid = ({
	children,
	className,
	container,
	fluid,
	row,
	rowFlex,
	col,
	offset,
	...rest
}) => (
	<div
		{...rest}
		className={cx(
			{
				container: container && !fluid,
				['container-fluid']: container && fluid,
				row,
				['row--flex']: row && rowFlex,
			},
			colToClassName(col),
			offsetToClassName(offset),
			className
		)}
	>
		{children}
	</div>
);

Grid.propTypes = {
	/** Children to be rendered in the main container. */
	children: PropTypes.node,
	/** Class name for root. */
	className: PropTypes.string,
	/** Grid system column. */
	col: PropTypes.arrayOf(PropTypes.number),
	/** If true, Grid represents container. */
	container: PropTypes.bool,
	/**
	 * If true, Grid represents fluid container. Use only if `container` is set
	 * to `true`.
	 */
	fluid: PropTypes.bool,
	/** If true, Grid represents column`s offset. Use only if `col` is set. */
	offset: PropTypes.arrayOf(PropTypes.number),
	/** If true, Grid represents row. */
	row: PropTypes.bool,
	/** If true, Grid represents row with flex. Use only if `row` is set to true*/
	rowFlex: PropTypes.bool,
};

export default Grid;
