import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import cheersImg from '../../../public/images/cheers_1.png';
import { APP_STATE } from '../../constants';

const PageFirst = ({ setAppState, setApplicationId }) => {
	useEffect(() => {
		setApplicationId(Date.now());
	}, [setApplicationId]);

	return (
		<main className="main text-center">
			<div className="container-fluid">
				<div className="page-hero">
					<img className="page-hero__img" src={cheersImg} alt="cheers 1" />
				</div>
				<div className="row">
					<div className="col-12">
						<h2>How it works</h2>
						<p>
							We gonna show you a text and then you have to rewrite it.
							Meanwhile, our magic is observing you, and then we tell you how
							likely you are drunk.
						</p>
						<button
							type="submit"
							className="btn btn-primary"
							onClick={() => setAppState(APP_STATE.SECOND)}
						>
							I am ready
						</button>
					</div>
				</div>
			</div>
		</main>
	);
};

PageFirst.propTypes = {
	setAppState: PropTypes.func.isRequired,
	setApplicationId: PropTypes.func.isRequired,
};

export default PageFirst;
