import React from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { applySpec } from 'ramda';

import { SHAPE_HISTORY, Translation, URL } from '../constants';
import { getLocale } from '../reducers';

import App from './App';

const Root = ({ store, locale, history }) => (
	<Provider store={store}>
		<IntlProvider locale={locale} messages={Translation.en}>
			<Router history={history}>
				<Switch>
					<Route exact path={URL.ROOT} component={App} />
				</Switch>
			</Router>
		</IntlProvider>
	</Provider>
);

Root.propTypes = {
	history: SHAPE_HISTORY.isRequired,
	locale: PropTypes.string,
	store: PropTypes.shape().isRequired,
};

Root.defaultProps = {
	locale: 'en',
};

export default connect(
	applySpec({
		locale: getLocale,
	})
)(Root);
