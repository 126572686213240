import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * This component is used for window scroll to the top on route change
 */
class ScrollToTop extends Component {
	static propTypes = {
		// eslint-disable-next-line react/forbid-prop-types
		children: PropTypes.node.isRequired,
		location: PropTypes.object.isRequired,
	};

	componentDidUpdate(prevProps) {
		const { location } = this.props;
		if (location.pathname !== prevProps.location.pathname) {
			window.scrollTo(0, 0);
		}
	}

	render() {
		const { children } = this.props;
		return children;
	}
}

export default withRouter(ScrollToTop);
